//https://codehabitude.com/2016/04/05/forms-to-emails-using-aws-lambda-api-gateway/
import {validEmail, validRequired} from './FormUtils';

const API_HOST = global.API_HOST;
const FORM_SELECTOR = '#contact-form';

const forEachElement = (selector, func) => {
    console.log("selector");
    console.log(selector);
    const elements = document.querySelectorAll(selector);
    Array.prototype.forEach.call(elements, (element, i) => {
        func(element, i);
    });
};

const post = (url, data, success, error) => {
    const request = new XMLHttpRequest();
    request.open('POST', url, true);
    request.setRequestHeader('Content-Type', 'application/json');

    request.onload = () => {
        if (request.status >= 200 && request.status < 400) {
            console.log("SUCCESS AJAX");
            var response = request.responseText;
            success(response);
        } else {
            console.log("FAILURE AJAX");
            error(JSON.parse(request.responseText));
        }
    };

    request.onerror = (e) => {
        // There was a connection error of some sort
        console.log("CONNECTION ERROR");
        console.log(e);
        error({"errors": [{"message": `unexpected error: status code: ${request.status}`}]});
    };

    request.send(JSON.stringify(data));
};

const clearFormErrors = () => {
    forEachElement(`${FORM_SELECTOR} .form-error`, (e) => {
        e.parentElement.removeChild(e);
    });
};

const setFormErrors = (errorString) => {
    console.log("setting form error");
    console.log(errorString);
    forEachElement(FORM_SELECTOR, (e) => {
        console.log(e);
        const errorsNode = document.createElement("p");
        errorsNode.className = "form-error";
        errorsNode.innerText = '* ' + errorString;
        e.insertBefore(errorsNode, e.firstChild);
    });
};

const enableSubmitButton = (enable) =>
    { forEachElement(`${FORM_SELECTOR} button`, (e,i) => { e.disabled = !enable; })};


const submitContactForm = (formElement) => {
    //var url = '/api/contact';
    clearFormErrors();
    //const url = 'https://prz7wg407d.execute-api.us-west-2.amazonaws.com/v1/api/leads';
    const url = `${API_HOST}/v1/api/leads`;

    const name = formElement.querySelectorAll('#name-input')[0].value;
    const email = formElement.querySelectorAll('#email-input')[0].value;
    const description = formElement.querySelectorAll('#description-input')[0].value;
    console.log(name);
    const data = {
        name: name,
        email: email,
        description: description
    };

    //disable form submit
    enableSubmitButton(false);


    console.log(data);
    const success = (response) => {
        console.log("success");
        forEachElement('.contact-form-wrapper', (e) => {
            e.style.display = "none";
        });
        forEachElement('.contact-form-success', (e) => {
            e.style.display = "block";
        });
    };
    const failure = (response) => {
        console.log("failure");
        console.log(response);
        const errorString = response.errors.map((e) => e.message).join(', ');
        setFormErrors(errorString);
        //re-enable button
        enableSubmitButton(true);
    }

    post(url, data, success, failure);

}

/*
    this is run first time through and then on key up
*/
const validateForm = (formSelector) => {
    let allValid = true;
    forEachElement(`${formSelector} [data-validate]`,
        (e, i) => {
            switch (e.dataset.validate) {
                case 'required':
                    if(!validRequired(e.value)) {
                        allValid = false;
                    }
                    break;
                case 'email':
                   if(!validRequired(e.value) || !validEmail(e.value)) {
                        allValid = false;
                   }
                   break;
            }
        }
    );

    enableSubmitButton(allValid);

};



export class ContactForm {
    constructor() {
        console.log("setting up contact form");
        forEachElement(`${FORM_SELECTOR} button`, (e, i) => {
            console.log(e);
            console.log(i);
            e.addEventListener("click",
                (event) => {
                    event.preventDefault();
                    console.log(event.target.parentElement);
                    submitContactForm(event.target.parentElement);
                }
            );
        });
        enableSubmitButton(false);

        forEachElement(`${FORM_SELECTOR} [data-validate]`,
            (e, i) => { e.addEventListener("keyup", (event) => { validateForm(FORM_SELECTOR); })}
        );

        console.log("API_HOST");
        console.log(API_HOST);

        /*
        document.querySelectorAll('#name-input')[0].value = "name value";
        document.querySelectorAll('#email-input')[0].value = "some@email.com";
        document.querySelectorAll('#description-input')[0].value = "description";
        */
        validateForm(FORM_SELECTOR);
    }
}
